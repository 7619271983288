import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  addLink: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    backgroundColor: '#4c50fc',
    borderRadius: 3,
    padding: '6px 15px',
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 300,

    '&:hover': {
      backgroundColor: '#247cf0',
    },
  },
});

export default useStyles;
