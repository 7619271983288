import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 608,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
    top: '50%',
    left: '50%',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    outline: '#fff',
    padding: '20px 30px',
    overflowX: 'hidden',
    overflowY: 'auto',

    [theme.breakpoints.up(768)]: {
      maxWidth: 478,
    },

    [theme.breakpoints.down(768)]: {
      margin: 15,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    padding: '10px 18px',
  },
  buttonApply: {
    backgroundColor: '#ff4c52',
    borderColor: '#ff4c52',
    marginLeft: 3,
  },
  buttonText: {
    fontWeight: 300,
    fontSize: 18,
    borderRadius: 5,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  iconStyle: {
    color: '#F8C785',
    borderColor: '#ff9800',
    width: 88,
    height: 88,
    animation: 'pulseWarning .75s infinite alternate',
  },
  title: {
    fontWeight: 400,
    fontSize: 34,
    color: '#424242',
    margin: '22px 0 11px',
    textAlign: 'center',
  },
  description: {
    color: '#616161',
    fontWeight: 100,
    fontSize: 22,
    marginTop: 0,
    textAlign: 'center',
  },
}));

export default useStyles;
