export enum RecommendersActionTypes {
  FETCH_RECOMMENDERS = '[RECOMMENDERS_MODULE] FETCH_RECOMMENDERS',
  FETCH_RECOMMENDERS_SUCCESS = '[RECOMMENDERS_MODULE] FETCH_RECOMMENDERS_SUCCESS',
  FETCH_RECOMMENDERS_ERROR = '[RECOMMENDERS_MODULE] FETCH_RECOMMENDERS_ERROR',

  FETCH_SINGLE_RECOMMENDER = '[RECOMMENDERS_MODULE] FETCH_SINGLE_RECOMMENDER',
  FETCH_SINGLE_RECOMMENDER_SUCCESS = '[RECOMMENDERS_MODULE] FETCH_SINGLE_RECOMMENDER_SUCCESS',
  FETCH_SINGLE_RECOMMENDER_ERROR = '[RECOMMENDERS_MODULE] FETCH_SINGLE_RECOMMENDER_ERROR',

  CREATE_RECOMMENDER = '[RECOMMENDERS_MODULE] CREATE_RECOMMENDER',
  CREATE_RECOMMENDER_SUCCESS = '[RECOMMENDERS_MODULE] CREATE_RECOMMENDER_SUCCESS',
  CREATE_RECOMMENDER_ERROR = '[RECOMMENDERS_MODULE] CREATE_RECOMMENDER_ERROR',

  EDIT_RECOMMENDER = '[RECOMMENDERS_MODULE] EDIT_RECOMMENDER',
  EDIT_RECOMMENDER_SUCCESS = '[RECOMMENDERS_MODULE] EDIT_RECOMMENDER_SUCCESS',
  EDIT_RECOMMENDER_ERROR = '[RECOMMENDERS_MODULE] EDIT_RECOMMENDER_ERROR',

  SEND_RECOMMENDERS_CONTRACT = '[RECOMMENDERS_MODULE] SEND_RECOMMENDERS_CONTRACT',
  SEND_RECOMMENDERS_CONTRACT_SUCCESS = '[RECOMMENDERS_MODULE] SEND_RECOMMENDERS_CONTRACT_SUCCESS',
  SEND_RECOMMENDERS_CONTRACT_ERROR = '[RECOMMENDERS_MODULE] SEND_RECOMMENDERS_CONTRACT_ERROR',

  CLOSE_SUCCESS_MESSAGE = '[RECOMMENDERS_MODULE] CLOSE_SUCCESS_MESSAGE',

  ACTIVATE_RECOMMENDER = '[RECOMMENDERS_MODULE] ACTIVATE_RECOMMENDER',
  ACTIVATE_RECOMMENDER_SUCCESS = '[RECOMMENDERS_MODULE] ACTIVATE_RECOMMENDER_SUCCESS',
  ACTIVATE_RECOMMENDER_ERROR = '[RECOMMENDERS_MODULE] ACTIVATE_RECOMMENDER_ERROR',
}
