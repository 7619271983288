export enum FreeUsersActionsTypes {
  FETCH_FREE_USERS = '[FREE_USERS_MODULE] FETCH_FREE_USERS',
  FETCH_FREE_USERS_SUCCESS = '[FREE_USERS_MODULE] FETCH_FREE_USERS_SUCCESS',
  FETCH_FREE_USERS_ERROR = '[FREE_USERS_MODULE] FETCH_FREE_USERS_ERROR',
  
  FETCH_FREE_ACCOUNT_DETAILS = '[FREE_USERS_MODULE] FETCH_FREE_ACCOUNT_DETAILS',
  FETCH_FREE_ACCOUNT_DETAILS_SUCCESS = '[FREE_USERS_MODULE] FETCH_FREE_ACCOUNT_DETAILS_SUCCESS',
  FETCH_FREE_ACCOUNT_DETAILS_ERROR = '[FREE_USERS_MODULE] FETCH_FREE_ACCOUNT_DETAILS_ERROR',

  EDIT_FREE_ACCOUNT = '[FREE_USERS_MODULE] EDIT_FREE_ACCOUNT',
  EDIT_FREE_ACCOUNT_SUCCESS = '[FREE_USERS_MODULE] EDIT_FREE_ACCOUNT_SUCCESS',
  EDIT_FREE_ACCOUNT_ERROR = '[FREE_USERS_MODULE] EDIT_FREE_ACCOUNT_ERROR',

  EDIT_FREE_MODULES = '[FREE_USERS_MODULE] EDIT_FREE_MODULES',
  EDIT_FREE_MODULES_SUCCESS = '[FREE_USERS_MODULE] EDIT_FREE_MODULES_SUCCESS',
  EDIT_FREE_MODULES_ERROR = '[FREE_USERS_MODULE] EDIT_FREE_MODULES_ERROR',

  CREATE_FREE_ACCOUNT = '[FREE_USERS_MODULE] CREATE_FREE_ACCOUNT',
  CREATE_FREE_ACCOUNT_SUCCESS = '[FREE_USERS_MODULE] CREATE_FREE_ACCOUNT_SUCCESS',
  CREATE_FREE_ACCOUNT_ERROR = '[FREE_USERS_MODULE] CREATE_FREE_ACCOUNT_ERROR',

  DELETE_FREE_ACCOUNT = '[FREE_USERS_MODULE] DELETE_FREE_ACCOUNT',
  DELETE_FREE_ACCOUNT_SUCCESS = '[FREE_USERS_MODULE] DELETE_FREE_ACCOUNT_SUCCESS',
  DELETE_FREE_ACCOUNT_ERROR = '[FREE_USERS_MODULE] DELETE_FREE_ACCOUNT_ERROR',

  LOGIN_FREE_ACCOUNT = '[FREE_USERS_MODULE] LOGIN_FREE_ACCOUNT',
  LOGIN_FREE_ACCOUNT_SUCCESS = '[FREE_USERS_MODULE] LOGIN_FREE_ACCOUNT_SUCCESS',
  LOGIN_FREE_ACCOUNT_ERROR = '[FREE_USERS_MODULE] LOGIN_FREE_ACCOUNT_ERROR',

  TOGGLE_ACTIVATION_FREE_ACCOUNT = '[FREE_USERS_MODULE] TOGGLE_ACTIVATION_FREE_ACCOUNT',
  TOGGLE_ACTIVATION_FREE_ACCOUNT_SUCCESS = '[FREE_USERS_MODULE] TOGGLE_ACTIVATION_FREE_ACCOUNT_SUCCESS',
  TOGGLE_ACTIVATION_FREE_ACCOUNT_ERROR = '[FREE_USERS_MODULE] TOGGLE_ACTIVATION_FREE_ACCOUNT_ERROR',

  FETCH_CHURN_TYPE_FREE_ACCOUNT = '[FREE_USERS_MODULE] FETCH_CHURN_TYPE_FREE_ACCOUNT',
  FETCH_CHURN_TYPE_FREE_ACCOUNT_SUCCESS = '[FREE_USERS_MODULE] FETCH_CHURN_TYPE_FREE_ACCOUNT_SUCCESS',
  FETCH_CHURN_TYPE_FREE_ACCOUNT_ERROR = '[FREE_USERS_MODULE] FETCH_CHURN_TYPE_FREE_ACCOUNT_ERROR',
}
