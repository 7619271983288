import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab';

import { Option } from 'libs/utils/types';

interface Props {
  defaultValue?: any;
  options: Array<Option>;
  name: string;
  onChange: (name: string, value: string | number | Array<string | number | object>) => void;
  getOptionLabel: (option: Option) => string;
  className?: string;
}

const MultipleAutocomplete: React.FC<Props> = ({
  defaultValue,
  options,
  name,
  onChange,
  getOptionLabel,
  className,
}) => {
  const [selectedOptions, setOptions] = useState<Array<string | number | object>>([]);

  useEffect(() => {
    onChange(name, selectedOptions);
  }, [selectedOptions, name, onChange]);

  const handleChange = (
    e: React.ChangeEvent<{}>,
    value: Array<any>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reason: AutocompleteChangeReason,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    details?: AutocompleteChangeDetails<any>,
  ): void => {
    setOptions(value);
  };

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => (
    <TextField
      {...params}
      variant="outlined"
      size="small"
      name={name}
    />
  );

  return (
    <Autocomplete
      multiple
      freeSolo
      options={options}
      getOptionLabel={getOptionLabel}
      defaultValue={defaultValue}
      renderInput={renderInput}
      fullWidth
      className={className}
      onChange={handleChange}
    />
  );
};

export default MultipleAutocomplete;
