import { SagaIterator } from 'redux-saga';
import { takeLatest, takeEvery, all } from 'redux-saga/effects';

import { RegionalManagementActionTypes } from 'modules/region-management/store/actions/actions';
import * as regionalManagementWorkers from './regional-management-saga';

export default function* regionalManagementSagaWatcher(): SagaIterator {
  yield all([
    takeLatest(RegionalManagementActionTypes.FETCH_COUNTRIES, regionalManagementWorkers.fetchCountriesWorker),
    takeEvery(RegionalManagementActionTypes.CREATE_COUNTRY, regionalManagementWorkers.createCountryWorker),
    takeLatest(RegionalManagementActionTypes.FETCH_CITIES, regionalManagementWorkers.fetchCitiesWorker),
    takeLatest(RegionalManagementActionTypes.FETCH_SINGLE_CITY, regionalManagementWorkers.fetchSingleCityWorker),
    takeLatest(RegionalManagementActionTypes.CREATE_CITY, regionalManagementWorkers.createCityWorker),
    takeEvery(RegionalManagementActionTypes.REMOVE_CITY, regionalManagementWorkers.removeCityWorker),
    takeEvery(RegionalManagementActionTypes.EDIT_CITY, regionalManagementWorkers.editCityWorker),
  ]);
}
