import { IssueType } from "modules/broadcast-issue/enums";
import { BannerActions, BroadcastIssueActions } from "../actions/actionTypes";

interface BroadcastStore {
  is_enable: number;
  message: string;
  state: IssueType;
  error: string;
}

const initialState = {
  is_enable: 0,
  message: "",
  state: IssueType.CRITICAL,
  error: "",
};

const bannerReducer = (
  state = initialState,
  action: BroadcastIssueActions
): BroadcastStore => {
  switch (action.type) {
    case BannerActions.PUT_BANNER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case BannerActions.GET_BANNER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case BannerActions.GET_BANNER_ERROR:
    case BannerActions.PUT_BANNER_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case BannerActions.CLEAR_BANNER_ERROR:
      return {
        ...state,
        error: "",
      };

    default:
      return state;
  }
};

export default bannerReducer;
