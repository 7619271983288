import { PaginationLinks, PaginationMeta } from 'libs/models';
import { Package } from 'modules/package-management/models';
import { PackageManagementActionTypes } from './actions';
import * as actionTypes from './actionTypes';

export const fetchPackages = (page?: number): actionTypes.FetchPackages => ({
  type: PackageManagementActionTypes.FETCH_PACKAGES,
  payload: {
    data: {
      page,
    },
  },
});

export const fetchPackagesSuccess = (payload: {
  items: Array<Package>;
  meta: PaginationMeta;
  links: PaginationLinks;
}): actionTypes.FetchPackagesSuccess => ({
  type: PackageManagementActionTypes.FETCH_PACKAGES_SUCCESS,
  payload,
});

export const fetchPackagesError = (payload: Array<string>): actionTypes.FetchPackagesError => ({
  type: PackageManagementActionTypes.FETCH_PACKAGES_ERROR,
  payload,
});

export const fetchSinglePackage = (packageId: number): actionTypes.FetchSinglePackage => ({
  type: PackageManagementActionTypes.FETCH_SINGLE_PACKAGE,
  payload: {
    data: {
      packageId,
    },
  },
});

export const fetchSinglePackageSuccess = (payload: Package): actionTypes.FetchSinglePackageSuccess => ({
  type: PackageManagementActionTypes.FETCH_SINGLE_PACKAGE_SUCCESS,
  payload,
});

export const fetchSinglePackageError = (payload: Array<string>): actionTypes.FetchSinglePackageError => ({
  type: PackageManagementActionTypes.FETCH_SINGLE_PACKAGE_ERROR,
  payload,
});

export const removePackage = (packageId: number): actionTypes.RemovePackage => ({
  type: PackageManagementActionTypes.REMOVE_PACKAGE,
  payload: {
    data: {
      packageId,
    },
  },
});

export const removePackageSuccess = (payload: { message: string; package_id: number }): actionTypes.RemovePackageSuccess => ({
  type: PackageManagementActionTypes.REMOVE_PACKAGE_SUCCESS,
  payload,
});

export const removePackageError = (payload: Array<string>): actionTypes.RemovePackageError => ({
  type: PackageManagementActionTypes.REMOVE_PACKAGE_ERROR,
  payload,
});

export const createPackage = (packageData: Package): actionTypes.CreatePackage => ({
  type: PackageManagementActionTypes.CREATE_PACKAGE,
  payload: {
    data: {
      packageData,
    },
  },
});

export const createPackageSuccess = (payload: Package): actionTypes.CreatePackageSuccess => ({
  type: PackageManagementActionTypes.CREATE_PACKAGE_SUCCESS,
  payload,
});

export const createPackageError = (payload: Array<string>): actionTypes.CreatePackageError => ({
  type: PackageManagementActionTypes.CREATE_PACKAGE_ERROR,
  payload,
});

export const editPackage = (packageData: Package): actionTypes.EditPackage => ({
  type: PackageManagementActionTypes.EDIT_PACKAGE,
  payload: {
    data: {
      packageData,
    },
  },
});

export const editPackageSuccess = (payload: Package): actionTypes.EditPackageSuccess => ({
  type: PackageManagementActionTypes.EDIT_PACKAGE_SUCCESS,
  payload,
});

export const editPackageError = (payload: Array<string>): actionTypes.EditPackageError => ({
  type: PackageManagementActionTypes.EDIT_PACKAGE_ERROR,
  payload,
});
