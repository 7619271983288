import React from 'react';
import { useField } from 'formik';

export interface FieldProps {
  name: string;
  value: string;
  error?: boolean;
  helperText?: string;
  onChange: (e: React.ChangeEvent<{ name?: string; value?: unknown }>) => void;
  onBlur?: (e: React.FocusEvent<{}>) => void;
}

type WithoutInjectedProps<P> = Omit<P, 'name' | 'value' | 'error' | 'helperText' | 'onChange' | 'onBlur'>;

interface Props<P> {
  name: string;
  component: React.ComponentType<P>;
  onChange?: (e: React.ChangeEvent<{ name?: string; value?: unknown }>) => void;
  isErrorVisible?: boolean;
}

export const Field = <P extends object>({
  name,
  component,
  onChange,
  isErrorVisible,
  ...props
}: Props<P> & WithoutInjectedProps<P>) => {
  const [field, meta] = useField<string>(name);
  const Component: any = component;

  const handleChange = (e: React.ChangeEvent<{ name?: string; value?: unknown }>) => {
    field.onChange(e);
    onChange?.(e);
  };

  return (
    <Component
      {...field}
      helperText={(isErrorVisible || meta.touched) && meta.error}
      error={(isErrorVisible || meta.touched) && Boolean(meta.error)}
      {...props}
      onChange={handleChange}
    />
  );
};
