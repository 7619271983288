import {
  FreeAccount,
  PaginationLinks,
  PaginationMeta,
  CompanyChurnData,
  ChurnData,
} from 'libs/models';
import * as actionTypes from './actionTypes';
import { Error, LoginWithFreeAccountData } from './actionTypes';
import {
  FreeUsersActionsTypes,
} from './actions';
import { QueryParams } from 'modules/free-users/utils/types';

export function fetchFreeUsers(params?: QueryParams): actionTypes.FetchFreeUsers {
  return ({
    type: FreeUsersActionsTypes.FETCH_FREE_USERS,
    payload: {
      params
    },
  });
}

export function fetchFreeUsersSuccess(payload: { items: Array<FreeAccount>; meta: PaginationMeta; links: PaginationLinks }): actionTypes.FetchFreeUsersSuccess {
  return ({
    type: FreeUsersActionsTypes.FETCH_FREE_USERS_SUCCESS,
    payload,
  });
}

export function fetchFreeUsersError(payload: Error): actionTypes.FetchFreeUsersError {
  return ({
    type: FreeUsersActionsTypes.FETCH_FREE_USERS_ERROR,
    payload,
  });
}

export function fetchSingleAccount(payload: { companyId: number }): actionTypes.FetchSingleAccount {
  return ({
    type: FreeUsersActionsTypes.FETCH_FREE_ACCOUNT_DETAILS,
    payload,
  });
}

export function fetchSingleAccountSuccess(payload: { company: FreeAccount }): actionTypes.FetchSingleAccountSuccess {
  return ({
    type: FreeUsersActionsTypes.FETCH_FREE_ACCOUNT_DETAILS_SUCCESS,
    payload,
  });
}

export function fetchSingleAccountError(payload: Error): actionTypes.FetchSingleAccountError {
  return ({
    type: FreeUsersActionsTypes.FETCH_FREE_ACCOUNT_DETAILS_ERROR,
    payload,
  });
}

export function createNewAccount(payload: { newFreeAccount: FreeAccount }): actionTypes.CreateFreeAccount {
  return ({
    type: FreeUsersActionsTypes.CREATE_FREE_ACCOUNT,
    payload,
  });
}

export function createNewAccountSuccess(payload: { newAccountId: number }): actionTypes.CreateFreeAccountSuccess {
  return ({
    type: FreeUsersActionsTypes.CREATE_FREE_ACCOUNT_SUCCESS,
    payload,
  });
}

export function createNewAccountError(payload: Error): actionTypes.CreateFreeAccountError {
  return ({
    type: FreeUsersActionsTypes.CREATE_FREE_ACCOUNT_ERROR,
    payload,
  });
}

export function editAccount(payload: { updatedCompany: FreeAccount }): actionTypes.EditFreeAccount {
  return ({
    type: FreeUsersActionsTypes.EDIT_FREE_ACCOUNT,
    payload,
  });
}

export function editAccountSuccess(): actionTypes.EditFreeAccountSuccess {
  return ({
    type: FreeUsersActionsTypes.EDIT_FREE_ACCOUNT_SUCCESS,
  });
}

export function editAccountError(payload: Error): actionTypes.EditFreeAccountError {
  return ({
    type: FreeUsersActionsTypes.EDIT_FREE_ACCOUNT_ERROR,
    payload,
  });
}

export function editFreeModules(payload: { key: string; value: number; companyId: number }): actionTypes.EditFreeModules {
  return ({
    type: FreeUsersActionsTypes.EDIT_FREE_MODULES,
    payload: {
      data: payload,
    },
  });
}

export function editFreeModulesSuccess(payload: { key: string; value: number; companyId: number }): actionTypes.EditFreeModulesSuccess {
  return ({
    type: FreeUsersActionsTypes.EDIT_FREE_MODULES_SUCCESS,
    payload: {
      data: payload,
    },
  });
}

export function editFreeModulesError(payload: Error): actionTypes.EditFreeModulesError {
  return ({
    type: FreeUsersActionsTypes.EDIT_FREE_MODULES_ERROR,
    payload,
  });
}


export function deleteAccount(payload: { companyId: number }): actionTypes.DeleteFreeAccount {
  return ({
    type: FreeUsersActionsTypes.DELETE_FREE_ACCOUNT,
    payload,
  });
}

export function deleteAccountSuccess(): actionTypes.DeleteFreeAccountSuccess {
  return ({
    type: FreeUsersActionsTypes.DELETE_FREE_ACCOUNT_SUCCESS,
  });
}

export function deleteAccountError(payload: Error): actionTypes.DeleteFreeAccountError {
  return ({
    type: FreeUsersActionsTypes.DELETE_FREE_ACCOUNT_ERROR,
    payload,
  });
}

export function loginWithFreeAccount(payload: { companyId: number }): actionTypes.LoginWithFreeAccount {
  return ({
    type: FreeUsersActionsTypes.LOGIN_FREE_ACCOUNT,
    payload,
  });
}

export function loginWithFreeAccountSuccess(payload: LoginWithFreeAccountData): actionTypes.LoginWithFreeAccountSuccess {
  return ({
    type: FreeUsersActionsTypes.LOGIN_FREE_ACCOUNT_SUCCESS,
    payload,
  });
}

export function loginWithFreeAccountError(payload: Error): actionTypes.LoginWithFreeAccountError {
  return ({
    type: FreeUsersActionsTypes.LOGIN_FREE_ACCOUNT_ERROR,
    payload,
  });
}

export function toggleActivationFreeAccount(payload: { id: number; churn?: CompanyChurnData }): actionTypes.ToggleActivationFreeAccount {
  return ({
    type: FreeUsersActionsTypes.TOGGLE_ACTIVATION_FREE_ACCOUNT,
    payload,
  });
}

export function toggleActivationFreeAccountSuccess(payload: { id: number; is_active: number }): actionTypes.ToggleActivationFreeAccountSuccess {
  return ({
    type: FreeUsersActionsTypes.TOGGLE_ACTIVATION_FREE_ACCOUNT_SUCCESS,
    payload,
  });
}

export function toggleActivationFreeAccountError(payload: Error): actionTypes.ToggleActivationFreeAccountError {
  return ({
    type: FreeUsersActionsTypes.TOGGLE_ACTIVATION_FREE_ACCOUNT_ERROR,
    payload,
  });
}

export function fetchChurnTypes(): actionTypes.FetchChurnTypesFreeAccount {
  return ({
    type: FreeUsersActionsTypes.FETCH_CHURN_TYPE_FREE_ACCOUNT,
  });
}

export function fetchChurnTypesSuccess(payload: Array<ChurnData>): actionTypes.FetchChurnTypesFreeAccountSuccess {
  return ({
    type: FreeUsersActionsTypes.FETCH_CHURN_TYPE_FREE_ACCOUNT_SUCCESS,
    payload,
  });
}

export function fetchChurnTypesError(payload: Error) {
  return ({
    type: FreeUsersActionsTypes.FETCH_CHURN_TYPE_FREE_ACCOUNT_ERROR,
    payload,
  });
}
