import React, { useEffect } from 'react';

import { AuthService } from 'modules/auth/services';

interface Props {
  children: React.ReactElement | React.FC;
}
const authService = AuthService.getInstance();

export const AuthMiddleware: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    if (!AuthService.getAuthState()) {
      authService.fetchLoginUrl().then(r => {
        localStorage.setItem('pkceVerifier', r?.data?.pkceVerifier);
        window.location.href = r?.data?.url;
      });
    }
  }, []);

  return (
    <>
      {children}
    </>
  );
};
