import { UpDownSells } from 'modules/up-down-sell/models';
import { UpDownSellsActions } from 'modules/up-down-sell/store/actions/actionTypes';
import { UpDownSellsActionTypes } from 'modules/up-down-sell/store/actions/actions';

const initialState: UpDownSells = {
  isFetching: false,
  errors: [],
  sells: [],
};

const upDownSellsReducer = (state: UpDownSells = initialState, action: UpDownSellsActions): UpDownSells => {
  switch (action.type) {
  case UpDownSellsActionTypes.FETCH_SELLS: {
    return {
      ...state,
      isFetching: true,
      errors: [],
    };
  }

  case UpDownSellsActionTypes.FETCH_SELLS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errors: [],
      sells: action.payload,
    };
  }

  case UpDownSellsActionTypes.FETCH_SELLS_ERROR: {
    return {
      ...state,
      isFetching: false,
      errors: action.payload,
    };
  }

  default:
    return state;
  }
};

export default upDownSellsReducer;
