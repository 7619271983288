import { Country } from 'libs/models';
import { City } from 'modules/region-management/models';
import { RegionalManagementActionTypes } from './actions';
import * as actionTypes from './actionTypes';

export const fetchCountries = (): actionTypes.FetchCountries => ({
  type: RegionalManagementActionTypes.FETCH_COUNTRIES,
});

export const fetchCountriesSuccess = (payload: Array<Country>): actionTypes.FetchCountriesSuccess => ({
  type: RegionalManagementActionTypes.FETCH_COUNTRIES_SUCCESS,
  payload,
});
  
export const fetchCountriesError = (payload: Array<string>): actionTypes.FetchCountriesError => ({
  type: RegionalManagementActionTypes.FETCH_COUNTRIES_ERROR,
  payload,
});

export const createCountry = (data: { name: string; code2: string }): actionTypes.CreateCountry => ({
  type: RegionalManagementActionTypes.CREATE_COUNTRY,
  payload: {
    data,
  },
});

export const createCountrySuccess = (payload: Country): actionTypes.CreateCountrySuccess => ({
  type: RegionalManagementActionTypes.CREATE_COUNTRY_SUCCESS,
  payload,
});

export const createCountryError = (payload: Array<string>): actionTypes.CreateCountryError => ({
  type: RegionalManagementActionTypes.CREATE_COUNTRY_ERROR,
  payload,
});

export const fetchCities = (): actionTypes.FetchCities => ({
  type: RegionalManagementActionTypes.FETCH_CITIES,
});

export const fetchCitiesSuccess = (payload: Array<City>): actionTypes.FetchCitiesSuccess => ({
  type: RegionalManagementActionTypes.FETCH_CITIES_SUCCESS,
  payload,
});

export const fetchCitiesError = (payload: Array<string>): actionTypes.FetchCitiesError => ({
  type: RegionalManagementActionTypes.FETCH_CITIES_ERROR,
  payload,
});

export const fetchSingleCity = (cityId: number): actionTypes.FetchSingleCity => ({
  type: RegionalManagementActionTypes.FETCH_SINGLE_CITY,
  payload: {
    data: {
      cityId,
    },
  },
});

export const fetchSingleCitySuccess = (payload: City): actionTypes.FetchSingleCitySuccess => ({
  type: RegionalManagementActionTypes.FETCH_SINGLE_CITY_SUCCESS,
  payload,
});

export const fetchSingleCityError = (payload: Array<string>): actionTypes.FetchSingleCityError => ({
  type: RegionalManagementActionTypes.FETCH_SINGLE_CITY_ERROR,
  payload,
});

export const cleanSingleCity = (): actionTypes.CleanSingleCity => ({
  type: RegionalManagementActionTypes.CLEAN_SINGLE_CITY,
});

export const createCity = (data: { name: string; country_id: number }): actionTypes.CreateCity => ({
  type: RegionalManagementActionTypes.CREATE_CITY,
  payload: {
    data,
  },
});

export const createCitySuccess = (payload: City): actionTypes.CreateCitySuccess => ({
  type: RegionalManagementActionTypes.CREATE_CITY_SUCCESS,
  payload,
});

export const createCityError = (payload: Array<string>): actionTypes.CreateCityError => ({
  type: RegionalManagementActionTypes.CREATE_CITY_ERROR,
  payload,
});

export const removeCity = (cityId: number): actionTypes.RemoveCity => ({
  type: RegionalManagementActionTypes.REMOVE_CITY,
  payload: {
    data: {
      cityId,
    },
  },
});

export const removeCitySuccess = (payload: { message: string; city_id: number }): actionTypes.RemoveCitySuccess => ({
  type: RegionalManagementActionTypes.REMOVE_CITY_SUCCESS,
  payload,
});

export const removeCityError = (payload: Array<string>): actionTypes.RemoveCityError => ({
  type: RegionalManagementActionTypes.REMOVE_CITY_ERROR,
  payload,
});

export const editCity = (data: { id: number; name: string; country_id: number }): actionTypes.EditCity => ({
  type: RegionalManagementActionTypes.EDIT_CITY,
  payload: {
    data,
  },
});

export const editCitySuccess = (payload: City): actionTypes.EditCitySuccess => ({
  type: RegionalManagementActionTypes.EDIT_CITY_SUCCESS,
  payload,
});

export const editCityError = (payload: Array<string>): actionTypes.EditCityError => ({
  type: RegionalManagementActionTypes.EDIT_CITY_ERROR,
  payload,
});
