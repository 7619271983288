export enum UserManagementActionTypes {
  FETCH_USERS = '[USER_MANAGEMENT_MODULE] FETCH_USERS',
  FETCH_USERS_SUCCESS = '[USER_MANAGEMENT_MODULE] FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR = '[USER_MANAGEMENT_MODULE] FETCH_USERS_ERROR',

  CHANGE_ADMIN = '[USER_MANAGEMENT_MODULE] CHANGE_ADMIN',
  CHANGE_ADMIN_SUCCESS = '[USER_MANAGEMENT_MODULE] CHANGE_ADMIN_SUCCESS',
  CHANGE_ADMIN_ERROR = '[USER_MANAGEMENT_MODULE] CHANGE_ADMIN_ERROR',

  FETCH_COMPANIES_TO_ASSIGN = '[USER_MANAGEMENT_MODULE] FETCH_COMPANIES_TO_ASSIGN',
  FETCH_COMPANIES_TO_ASSIGN_SUCCESS = '[USER_MANAGEMENT_MODULE] FETCH_COMPANIES_TO_ASSIGN_SUCCESS',
  FETCH_COMPANIES_TO_ASSIGN_ERROR = '[USER_MANAGEMENT_MODULE] FETCH_COMPANIES_TO_ASSIGN_ERROR',

  ASSIGN_COMPANY_TO_USER = '[USER_MANAGEMENT_MODULE] ASSIGN_COMPANY_TO_USER',
  ASSIGN_COMPANY_TO_USER_SUCCESS = '[USER_MANAGEMENT_MODULE] ASSIGN_COMPANY_TO_USER_SUCCESS',
  ASSIGN_COMPANY_TO_USER_ERROR = '[USER_MANAGEMENT_MODULE] ASSIGN_COMPANY_TO_USER_ERROR',

  DEASSIGN_COMPANY_TO_USER = '[USER_MANAGEMENT_MODULE] DEASSIGN_COMPANY_TO_USER',
  DEASSIGN_COMPANY_TO_USER_SUCCESS = '[USER_MANAGEMENT_MODULE] DEASSIGN_COMPANY_TO_USER_SUCCESS',
  DEASSIGN_COMPANY_TO_USER_ERROR = '[USER_MANAGEMENT_MODULE] DEASSIGN_COMPANY_TO_USER_ERROR',

  OFFBOARDING_USER = '[USER_MANAGEMENT_MODULE] OFFBOARDING_USER',
  OFFBOARDING_USER_SUCCESS = '[USER_MANAGEMENT_MODULE] OFFBOARDING_USER_SUCCESS',
  OFFBOARDING_USER_ERROR = '[USER_MANAGEMENT_MODULE] OFFBOARDING_USER_ERROR',
}
