import { ModifiedChecboxProps, CheckboxOption } from "libs/utils/types";

export interface Module {
  company_id?: number | null;
  is_banner_bots: number | null;
  is_cta_bot: number | null;
  is_chat_bot: number | null;
  is_visitor_tracking: number | null;
  is_inpage_bot: number | null;
  is_live_chat?: number | null;
  is_media_partner: number | null;
  is_visual_bot: number | null;
  is_page: number | null;
  is_company_identification?: number;
  is_advanced_analytics?: number | null;
  is_workflows?: number;
  is_integrations?: number | null;
  is_calendar_booking?: number | null;
  is_sales_assistant?: number | null;
  is_personal_bot_premium?: number | null;
  is_source_insights?: number | null;
  is_ab_experiment?: number | null;
  is_lead_stage?: number | null;
  is_journey_insights?: number | null;
  is_leadoo_personalization?: number | null;
  is_lookup?: number | null;
  is_profanity_checker?: number | null;
  is_dashboard?: number | null;
  is_analytics_tracking_core?: number | null;
  is_leads_core?: number | null;
  is_bot_core?: number | null;
  is_custom_roles?: number | null;
  is_email_logs?: number | null;
  is_audit_logs?: number | null;
  is_company_management?: number | null;
  is_team_management?: number | null;
  is_advanced_source_insights?: number | null;
  is_website_analytics?: number | null;
  is_artificial_intelligence?: number | null;
  is_event_tracking?: number | null;
}

export enum ModuleKeys {
  is_banner_bots = "is_banner_bots",
  is_cta_bot = "is_cta_bot",
  is_chat_bot = "is_chat_bot",
  is_visitor_tracking = "is_visitor_tracking",
  is_inpage_bot = "is_inpage_bot",
  is_live_chat = "is_live_chat",
  is_media_partner = "is_media_partner",
  is_visual_bot = "is_visual_bot",
  is_page = "is_page",
  is_company_identification = "is_company_identification",
  is_advanced_analytics = "is_advanced_analytics",
  is_workflows = "is_workflows",
  is_integrations = "is_integrations",
  is_calendar_booking = "is_calendar_booking",
  is_sales_assistant = "is_sales_assistant",
  is_personal_bot_premium = "is_personal_bot_premium",
  is_source_insights = "is_source_insights",
  is_ab_experiment = "is_ab_experiment",
  is_lead_stage = "is_lead_stage",
  is_journey_insights = "is_journey_insights",
  is_leadoo_personalization = "is_leadoo_personalization",
  is_lookup = "is_lookup",
  is_profanity_checker = "is_profanity_checker",
  is_dashboard = "is_dashboard",
  is_analytics_tracking_core = "is_analytics_tracking_core",
  is_leads_core = "is_leads_core",
  is_bot_core = "is_bot_core",
  is_custom_roles = "is_custom_roles",
  is_email_logs = "is_email_logs",
  is_audit_logs = "is_audit_logs",
  is_company_management = "is_company_management",
  is_team_management = "is_team_management",
  is_advanced_source_insights = "is_advanced_source_insights",
  is_website_analytics = "is_website_analytics",
  is_artificial_intelligence = "is_artificial_intelligence",
  is_event_tracking = "is_event_tracking",
}
export const nonFreeModules: Array<ModuleKeys> = [];

export const getCheckboxes = ({
  classes,
}: ModifiedChecboxProps): Array<CheckboxOption> => [
  {
    label: "ChatBot",
    name: ModuleKeys.is_chat_bot,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Page",
    name: ModuleKeys.is_page,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "InpageBot",
    name: ModuleKeys.is_inpage_bot,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "VisualBot",
    name: ModuleKeys.is_visual_bot,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "CTABot",
    name: ModuleKeys.is_cta_bot,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Livechat",
    name: ModuleKeys.is_live_chat,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Media Partner",
    name: ModuleKeys.is_media_partner,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Visitor Tracking",
    name: ModuleKeys.is_visitor_tracking,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Company identification",
    name: ModuleKeys.is_company_identification,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "BannerBots",
    name: ModuleKeys.is_banner_bots,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Bot and LiveChat analytic",
    name: ModuleKeys.is_advanced_analytics,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Workflows",
    name: ModuleKeys.is_workflows,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Integrations",
    name: ModuleKeys.is_integrations,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Calendar Booking",
    name: ModuleKeys.is_calendar_booking,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Sales Assistant",
    name: ModuleKeys.is_sales_assistant,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Sales Assistant Premium",
    name: ModuleKeys.is_personal_bot_premium,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Lead stage",
    name: ModuleKeys.is_lead_stage,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "A/B Testing",
    name: ModuleKeys.is_ab_experiment,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Personalizer",
    name: ModuleKeys.is_leadoo_personalization,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Lookup",
    name: ModuleKeys.is_lookup,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Advanced analytics",
    name: ModuleKeys.is_source_insights,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Journey Insights",
    name: ModuleKeys.is_journey_insights,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Profanity checker",
    name: ModuleKeys.is_profanity_checker,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Dashboard",
    name: ModuleKeys.is_dashboard,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Analytics tracking core",
    name: ModuleKeys.is_analytics_tracking_core,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Leads core",
    name: ModuleKeys.is_leads_core,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Bot core",
    name: ModuleKeys.is_bot_core,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Custom roles",
    name: ModuleKeys.is_custom_roles,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Email logs",
    name: ModuleKeys.is_email_logs,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Audit logs",
    name: ModuleKeys.is_audit_logs,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Company management",
    name: ModuleKeys.is_company_management,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Team management",
    name: ModuleKeys.is_team_management,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Source Insights",
    name: ModuleKeys.is_advanced_source_insights,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Website analytics",
    name: ModuleKeys.is_website_analytics,
    className: classes ? classes.tableCellText : "",
  },
  {
    label: "Artificial Intelligence",
    name: ModuleKeys.is_artificial_intelligence,
    className: classes ? classes.tableCellText : "",
  },
];

export const getModuleBlocks = ({ classes }: ModifiedChecboxProps) => [
  {
    groups: [
      {
        groupName: "Bots:",
        items: [
          {
            label: "Core",
            name: ModuleKeys.is_bot_core,
            className: classes ? classes.tableCellText : "",
            bubbleMessage:
              "Core includes all the basic features needed to manage the bots such as general bot settings, bot insights and dashboard",
          },
          {
            label: "ChatBot",
            name: ModuleKeys.is_chat_bot,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "ChatBot tooltip placeholder",
          },
          {
            label: "InpageBot",
            name: ModuleKeys.is_inpage_bot,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "InpageBot tooltip placeholder",
          },
          {
            label: "VisualBot",
            name: ModuleKeys.is_visual_bot,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "VisualBot tooltip placeholder",
          },
          {
            label: "CTABot",
            name: ModuleKeys.is_cta_bot,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "CTABot tooltip placeholder",
          },
        ],
      },
    ],
  },
  {
    groups: [
      {
        groupName: "Advanced Bot Features:",
        items: [
          {
            label: "BannerBots",
            name: ModuleKeys.is_banner_bots,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "BannerBots tooltip placeholder",
          },
          {
            label: "A/B Testing",
            name: ModuleKeys.is_ab_experiment,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "A/B Testing tooltip placeholder",
          },
          {
            label: "Calendar Booking",
            name: ModuleKeys.is_calendar_booking,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Calendar Booking tooltip placeholder",
          },
          {
            label: "Lookup",
            name: ModuleKeys.is_lookup,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Lookup tooltip placeholder",
          },
          {
            label: "Personaliser",
            name: ModuleKeys.is_leadoo_personalization,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Personaliser tooltip placeholder",
          },
          {
            label: "Sales Assistant",
            name: ModuleKeys.is_sales_assistant,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Sales Assistant tooltip placeholder",
          },
          {
            label: "Artificial Intelligence",
            name: ModuleKeys.is_artificial_intelligence,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Artificial Intelligence tooltip placeholder",
          },
        ],
      },
    ],
  },
  {
    groups: [
      {
        groupName: "Leads:",
        items: [
          {
            label: "Core",
            name: ModuleKeys.is_leads_core,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Leads core tooltip placeholder",
          },
          {
            label: "Lead Stage",
            name: ModuleKeys.is_lead_stage,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Lead Stage tooltip placeholder",
          },
        ],
      },
      {
        groupName: "LiveChat:",
        items: [
          {
            label: "Core",
            name: ModuleKeys.is_live_chat,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Livechat core tooltip placeholder",
          },
        ],
      },
    ],
  },
  {
    groups: [
      {
        groupName: "Analytics & Tracking:",
        items: [
          {
            label: "Core",
            name: ModuleKeys.is_analytics_tracking_core,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Analytics & Tracking tooltip placeholder",
          },
          {
            label: "Dashboard",
            name: ModuleKeys.is_dashboard,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Dashboard tooltip placeholder",
          },
          {
            label: "Website Analytics",
            name: ModuleKeys.is_website_analytics,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Website Analytics tooltip placeholder",
          },
          {
            label: "Visitor Tracking",
            name: ModuleKeys.is_visitor_tracking,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Visitor Tracking tooltip placeholder",
          },
          {
            label: "Journey Insights",
            name: ModuleKeys.is_journey_insights,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Journey Insight tooltip placeholder",
          },
          {
            label: "Source Insights",
            name: ModuleKeys.is_source_insights,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Source Insights tooltip placeholder",
          },
          {
            label: "Workflows",
            name: ModuleKeys.is_workflows,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Workflows tooltip placeholder",
          },
          {
            label: "Company Identification",
            name: ModuleKeys.is_company_identification,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Company Identification tooltip placeholder",
          },
          {
            label: "Event tracking",
            name: ModuleKeys.is_event_tracking,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Event tracking tooltip placeholder",
          },
        ],
      },
    ],
  },
  {
    groups: [
      {
        groupName: "Account & Team:",
        items: [
          {
            label: "Team Mangement",
            name: ModuleKeys.is_team_management,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Account & Team tooltip placeholder",
          },
          {
            label: "Company Information",
            name: ModuleKeys.is_company_management,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Company Information tooltip placeholder",
          },
          {
            label: "Audit Logs",
            name: ModuleKeys.is_audit_logs,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Audit Logs tooltip placeholder",
          },
          {
            label: "Email Logs",
            name: ModuleKeys.is_email_logs,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Email Logs tooltip placeholder",
          },
          {
            label: "Custom Roles",
            name: ModuleKeys.is_custom_roles,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Custom Roles tooltip placeholder",
          },
          {
            label: "Integrations",
            name: ModuleKeys.is_integrations,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Integrations tooltip placeholder",
          },
          {
            label: "Media Partner",
            name: ModuleKeys.is_media_partner,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Media Partner tooltip placeholder",
          },
          {
            label: "Profanity Checker",
            name: ModuleKeys.is_profanity_checker,
            className: classes ? classes.tableCellText : "",
            bubbleMessage: "Profanity Checker tooltip placeholder",
          },
        ],
      },
    ],
  },
];
