import { isEmpty } from 'lodash';

import { RecommendersStore, Recommender } from 'modules/recommenders/models';
import { RecommendersActionTypes } from 'modules/recommenders/store/actions/actions';
import { RecommendersActions } from 'modules/recommenders/store/actions/actionTypes';

const initialState: RecommendersStore = {
  isFetching: false,
  errorMessages: [],
  successMessage: '',
  items: [],
  item: null,
  currentPage: 1,
  totalItems: 0,
  totalPages: 1,
  createdRecommenderId: 0,
  noDataMessage: '',
};

const recommendersReducer = (state: RecommendersStore = initialState, action: RecommendersActions): RecommendersStore => {
  switch (action.type) {
  case RecommendersActionTypes.FETCH_RECOMMENDERS:
  case RecommendersActionTypes.FETCH_SINGLE_RECOMMENDER:
  case RecommendersActionTypes.CREATE_RECOMMENDER:
  case RecommendersActionTypes.EDIT_RECOMMENDER:
  case RecommendersActionTypes.SEND_RECOMMENDERS_CONTRACT:
  case RecommendersActionTypes.ACTIVATE_RECOMMENDER: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      noDataMessage: '',
    };
  }

  case RecommendersActionTypes.CLOSE_SUCCESS_MESSAGE: {
    return {
      ...state,
      successMessage: '',
    };
  }

  case RecommendersActionTypes.FETCH_RECOMMENDERS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      noDataMessage: isEmpty(action.payload.items) ? 'There are no recommenders yet!' : '',
      items: action.payload.items,
      totalItems: action.payload.meta.totalItems,
      totalPages: action.payload.meta.totalPages,
      currentPage: action.payload.meta.currentPage,
    };
  }

  case RecommendersActionTypes.FETCH_SINGLE_RECOMMENDER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      item: action.payload,
    };
  }

  case RecommendersActionTypes.CREATE_RECOMMENDER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      items: [
        ...state.items,
        action.payload,
      ],
      createdRecommenderId: Number(action.payload.id),
      successMessage: 'Recommender has been stored successfully!',
    };
  }

  case RecommendersActionTypes.EDIT_RECOMMENDER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      items: state.items.map((item: Recommender) => item.id === action.payload.id ? ({ ...item, ...action.payload }) : item),
      successMessage: 'Recommender has been stored successfully!',
    };
  }

  case RecommendersActionTypes.SEND_RECOMMENDERS_CONTRACT_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      successMessage: 'Recommender has been stored successfully',
    };
  }

  case RecommendersActionTypes.ACTIVATE_RECOMMENDER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      successMessage: 'Recommender has been activated successfully!',
    };
  }

  case RecommendersActionTypes.FETCH_RECOMMENDERS_ERROR:
  case RecommendersActionTypes.FETCH_SINGLE_RECOMMENDER_ERROR:
  case RecommendersActionTypes.CREATE_RECOMMENDER_ERROR:
  case RecommendersActionTypes.EDIT_RECOMMENDER_ERROR:
  case RecommendersActionTypes.SEND_RECOMMENDERS_CONTRACT_ERROR:
  case RecommendersActionTypes.ACTIVATE_RECOMMENDER_ERROR: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [
        ...state.errorMessages,
        ...action.payload,
      ],
    };
  }

  default:
    return state;
  }
};

export default recommendersReducer;
