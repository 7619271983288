import { AxiosResponse } from 'axios';

import { BaseService } from 'libs/services';
import { CreatedBot, AssignedBot } from 'modules/bot-created-count/models';

export class BotCreatedCountService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: BotCreatedCountService;

  constructor() {
    super();

    if (BotCreatedCountService._instance) {
      throw Error('Unable to create an instance!');
    }
  }

  static getInstance(): BotCreatedCountService {
    if (!BotCreatedCountService._instance) {
      BotCreatedCountService._instance = new BotCreatedCountService();
    }

    return BotCreatedCountService._instance;
  }

  public fetchCreatedBots = (params?: { from?: string; to?: string }): Promise<AxiosResponse<Array<CreatedBot>>> => {
    return this.get(`${this.ADMIN_API_ROUTE}/bot/createdCount`, { params });
  };

  public fetchAssignedBots = (botId: number, from: string, to: string): Promise<AxiosResponse<Array<AssignedBot>>> => {
    return this.get(`${this.ADMIN_API_ROUTE}/bot/createdCount/${botId}?from=${from}&to=${to}`);
  };
}
