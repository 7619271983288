import { SagaIterator } from 'redux-saga';
import { takeLatest, all, takeEvery } from 'redux-saga/effects';

import { UserManagementActionTypes } from 'modules/user-management/store/actions/actions';
import * as userManagementWorkers from './user-management';

export default function* (): SagaIterator {
  yield all([
    takeLatest(UserManagementActionTypes.FETCH_USERS, userManagementWorkers.fetchUsersWorker),
    takeEvery(UserManagementActionTypes.CHANGE_ADMIN, userManagementWorkers.changeAdminWorker),
    takeLatest(UserManagementActionTypes.FETCH_COMPANIES_TO_ASSIGN, userManagementWorkers.fetchCompaniesToAssignWorker),
    takeEvery(UserManagementActionTypes.ASSIGN_COMPANY_TO_USER, userManagementWorkers.assignCompanyToUserWorker),
    takeEvery(UserManagementActionTypes.OFFBOARDING_USER, userManagementWorkers.offboardingUserWorker),
    takeEvery(UserManagementActionTypes.DEASSIGN_COMPANY_TO_USER, userManagementWorkers.deassignCompanyToUserWorker),
  ]);
}
