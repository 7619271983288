import { isEmpty } from 'lodash';

import { BotCreatedCount } from 'modules/bot-created-count/models';
import { BotCreatedCountActionTypes } from 'modules/bot-created-count/store/actions/actions';
import { BotCreatedCountActions } from 'modules/bot-created-count/store/actions/actionTypes';

const initialState: BotCreatedCount = {
  isFetching: false,
  errorMessages: [],
  createdBots: [],
  assignedBots: [],
  noDataMessage: '',
};

const botCreatedCountReducer = (state: BotCreatedCount = initialState, action: BotCreatedCountActions): BotCreatedCount => {
  switch (action.type) {
  case BotCreatedCountActionTypes.FETCH_CREATED_BOTS:
  case BotCreatedCountActionTypes.FETCH_ASSIGNED_BOTS: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      noDataMessage: '',
    };
  }

  case BotCreatedCountActionTypes.CLEAN_ASSIGNED_BOTS: {
    return {
      ...state,
      assignedBots: [],
    };
  }

  case BotCreatedCountActionTypes.FETCH_CREATED_BOTS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      createdBots: action.payload,
      noDataMessage: isEmpty(action.payload) ? 'There are no created bots yet!' : '',
    };
  }

  case BotCreatedCountActionTypes.FETCH_ASSIGNED_BOTS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      assignedBots: action.payload,
      noDataMessage: isEmpty(action.payload) ? 'There are no created bots info yet!' : '',
    };
  }

  case BotCreatedCountActionTypes.FETCH_CREATED_BOTS_ERROR:
  case BotCreatedCountActionTypes.FETCH_ASSIGNED_BOTS_ERROR: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [
        ...state.errorMessages,
        ...action.payload,
      ],
    };
  }
  
  default:
    return state;
  }
};

export default botCreatedCountReducer;
