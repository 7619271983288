import { all } from "redux-saga/effects";

import { authWatcher } from "modules/auth/store/sagas";
import companyManagementSaga from "modules/company-management/store/sagas";
import freeUsersSaga from "modules/free-users/store/sagas";
import userManagementSaga from "modules/user-management/store/sagas";
import mediaPartnerBotSaga from "modules/media-partner-bot/store/sagas";
import botCreatedCountSaga from "modules/bot-created-count/store/sagas";
import recommendersSaga from "modules/recommenders/store/sagas";
import regionalManagementSaga from "modules/region-management/store/sagas";
import packageManagementSaga from "modules/package-management/store/sagas";
import upDownSellsSaga from "modules/up-down-sell/store/sagas";
import integrationsErrorsSaga from "modules/integrations/store/sagas";
import bannerSaga from "modules/broadcast-issue/store/sagas";

export default function* rootSaga() {
  yield all([
    authWatcher(),
    companyManagementSaga(),
    freeUsersSaga(),
    userManagementSaga(),
    mediaPartnerBotSaga(),
    botCreatedCountSaga(),
    recommendersSaga(),
    regionalManagementSaga(),
    packageManagementSaga(),
    upDownSellsSaga(),
    integrationsErrorsSaga(),
    bannerSaga(),
  ]);
}
