import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React, {Fragment} from 'react';

const apiKey: string = process.env.REACT_APP_BUGSNAG_API_KEY || "";
const releaseStage = process.env.REACT_APP_ENVIRONMENT || "development";

if (releaseStage !== 'local') {
  Bugsnag.start({
    apiKey: apiKey,
    appType: 'Admin App New FE',
    releaseStage: releaseStage,
    enabledReleaseStages: ['staging', 'production'],
    plugins: [new BugsnagPluginReact()],
    onError: function (event) {
      const objIgnores = [
        'loading chunk',
        'token info lookup',
        'network error',
        'resizeobserver loop completed with undelivered notifications',
      ];

      let sendError = true;

      objIgnores.forEach(errorName => {
        const ignore = event.errors.filter(obj => JSON.stringify(obj).toLowerCase().includes(errorName.toLowerCase()));

        if (ignore.length) {
          sendError = false;
        }
      });
      return sendError;
    }
  });
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? Fragment;

export default ErrorBoundary;
