import {BaseService} from '../../../libs/services';
import {FetchIntegrationsErrorsQueryParams, IntegrationError, UpdateIntegrationErrorPayload} from '../types';

const baseUrl = process.env.REACT_APP_ADMIN_API_URL;

export class IntegrationsErrorsService extends BaseService {
  static _instance: IntegrationsErrorsService;

  constructor() {
    super();

    if (IntegrationsErrorsService._instance) {
      throw Error('Unable to create new instance!');
    }
  }

  static getInstance(): IntegrationsErrorsService {
    if (!IntegrationsErrorsService._instance) {
      IntegrationsErrorsService._instance = new IntegrationsErrorsService();
    }

    return IntegrationsErrorsService._instance;
  }

  fetchErrorsList = async (params: FetchIntegrationsErrorsQueryParams): Promise<{
    list: IntegrationError[];
    totalCount: number;
  }> => {
    const url = new URL(`${baseUrl}/cyclr/integrations-errors`);

    Object.entries(params).forEach(([key, value]) => {
      if (value !== '') {
        url.searchParams.append(key, value as string);
      }
    });

    const res = await this.get(url.toString());
    return res.data;
  }

  updateError = async (data: UpdateIntegrationErrorPayload): Promise<void> => {
    const url = new URL(`${baseUrl}/cyclr/integrations-errors/${data.id}/update`);
    await this.post(url.toString(), data);
  }
}
