import { AxiosResponse } from "axios";

import { BaseService } from "libs/services";
import {
  ClearbitData,
  Company,
  CompanyChurnData,
  HistoricalLeadsData,
  User,
} from "libs/models";
import { QueryParams } from "modules/company-management/utils/types";
import {
  Contract,
  ContractHistory,
  DuplicateCompanyData,
  Platform,
} from "modules/company-management/models";
import { ContractHistoriesParams } from "modules/company-management/store/actions/actionCreators";
import { SettingPayload } from "modules/company-management/components/common/CompanyManagement/CompanyView/SettingsList/interfaces";

type ResponseOptions<T> = {
  data: T;
  message: string;
  statusCode: number;
};

type BlankPlatformOptions = {
  company_id: number;
  code: string;
  platform_value: string;
};

export class CompanyManagementService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;
  private CAS_API_ROUTE = this.CAS_API_HOST;

  static _instance: CompanyManagementService;

  constructor() {
    super();

    if (CompanyManagementService._instance) {
      throw Error("Unable to create new instance!");
    }
  }

  static getInstance(): CompanyManagementService {
    if (!CompanyManagementService._instance) {
      CompanyManagementService._instance = new CompanyManagementService();
    }

    return CompanyManagementService._instance;
  }

  public fetchCompanies = async (
    params?: QueryParams
  ): Promise<AxiosResponse> => {
    let queryParams: object = {};

    if (params && params!.is_active && params!.is_active === "3") {
      delete params.is_active;

      queryParams = {
        ...params,
        saler_id: "null",
      };
    } else {
      queryParams = { ...queryParams, ...params };
    }

    const res = await this.get(`${this.ADMIN_API_ROUTE}/company/v2`, {
      params: queryParams,
    });

    return res;
  };

  public fetchSingleCompany = (companyId: number): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_ROUTE}/company/${companyId}`);
  };

  public addCompany = (company: Company): Promise<AxiosResponse> => {
    return this.post(`${this.ADMIN_API_ROUTE}/company`, company);
  };

  public deleteUserFromCompany = ({
    companyId,
    userId,
  }: {
    companyId: number;
    userId: number;
  }): Promise<AxiosResponse> => {
    return this.delete(
      `${this.ADMIN_API_ROUTE}/company/${companyId}/user/${userId}`
    );
  };

  public editCompanyModules = ({
    key,
    value,
    companyId,
  }: {
    key: string;
    value: any;
    companyId: number;
  }): Promise<AxiosResponse> => {
    return this.put(`${this.ADMIN_API_ROUTE}/module/${companyId}`, {
      key,
      value,
    });
  };

  public editCompany = (company: Company): Promise<AxiosResponse> => {
    return this.put(`${this.ADMIN_API_ROUTE}/company/${company.id}`, company);
  };

  public editCompanySetting = ({
    companyId,
    setting,
  }: {
    companyId: number;
    setting: SettingPayload;
  }): Promise<AxiosResponse> => {
    return this.put(
      `${this.ADMIN_API_ROUTE}/company/${companyId}/setting`,
      setting
    );
  };

  public removeCompany = (companyId: number): Promise<AxiosResponse> => {
    return this.delete(`${this.ADMIN_API_ROUTE}/company/${companyId}`);
  };

  public sendContract = (
    companyId: number | string
  ): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_ROUTE}/company/resend/${companyId}`);
  };

  public sendOldContract = ({
    companyId,
    email,
  }: {
    companyId: number;
    email: string;
  }): Promise<AxiosResponse> => {
    return this.post(
      `${this.ADMIN_API_ROUTE}/company/send-old-contract/${companyId}`,
      { email }
    );
  };

  public syncCompanyWithPlanhat = (
    companyId: string
  ): Promise<AxiosResponse> => {
    return this.put(
      `${this.ADMIN_API_ROUTE}/company/${companyId}/planhat/sync`
    );
  };

  public syncCompanyWithYounium = (
    companyId: string
  ): Promise<AxiosResponse> => {
    return this.put(
      `${this.ADMIN_API_ROUTE}/company/${companyId}/younium/sync`
    );
  };

  public fetchCountries = (): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_ROUTE}/country`);
  };

  public fetchCurrencies = (): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_ROUTE}/currency`);
  };

  public fetchChurnTypes = (): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_ROUTE}/churn`);
  };

  public fetchClearbit = (
    companyId: number
  ): Promise<AxiosResponse<ClearbitData>> => {
    return this.get(`${this.ADMIN_API_ROUTE}/clearbit/${companyId}`);
  };

  public enableDomain = ({
    companyId,
    domain,
    limit,
  }: {
    companyId: number;
    domain: string;
    limit: number;
  }): Promise<AxiosResponse<{ message: string; clearbit: ClearbitData }>> => {
    return this.post(`${this.ADMIN_API_ROUTE}/clearbit/${companyId}`, {
      domain,
      limit,
    });
  };

  public setLimit = ({
    companyId,
    limit,
  }: {
    companyId: number;
    limit: number;
  }) => {
    return this.patch(`${this.ADMIN_API_ROUTE}/clearbit/${companyId}/limit`, {
      limit,
    });
  };

  public disableDomain = (
    companyId: number
  ): Promise<AxiosResponse<ClearbitData>> => {
    return this.delete(`${this.ADMIN_API_ROUTE}/clearbit/${companyId}`);
  };

  public fetchBots = (companyId: number): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_ROUTE}/company/share-bot/${companyId}`);
  };

  public saveBot = (data: {
    bot_id: number;
    media_company_id: string | number | null;
    code_media: string | null;
    company_id: number;
  }): Promise<AxiosResponse> => {
    return this.put(
      `${this.ADMIN_API_ROUTE}/company/share-bot/${data.company_id}`,
      data
    );
  };

  public fetchSalesPeople = (): Promise<AxiosResponse<Array<User>>> =>
    this.get(`${this.ADMIN_API_ROUTE}/users/salers`);

  public fetchIndustries = (): Promise<AxiosResponse> =>
    this.get(`${this.ADMIN_API_ROUTE}/industry/list`);

  public fetchIndustrySubClasses = (): Promise<AxiosResponse> =>
    this.get(`${this.ADMIN_API_ROUTE}/industry/subs`);

  public fetchAdminLogs = (
    params?: QueryParams,
    companyId?: string
  ): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_ROUTE}/admin-log/logs/${companyId}`, {
      params,
    });
  };

  public fetchCompanyContracts = (
    params?: QueryParams,
    companyId?: string
  ): Promise<AxiosResponse> => {
    return this.get(
      `${this.ADMIN_API_ROUTE}/company-contract/company/${companyId}`,
      { params }
    );
  };

  public switchToCompany = (companyId: number): Promise<AxiosResponse> =>
    this.get(`${this.ADMIN_API_ROUTE}/company/switch-targets/${companyId}`);

  public duplicateCompany = (
    duplicateData: DuplicateCompanyData,
    companyId: number
  ): Promise<AxiosResponse> => {
    return this.post(
      `${this.ADMIN_API_ROUTE}/company/duplicate/${companyId}`,
      duplicateData
    );
  };

  public resendActivation = (companyId: number): Promise<AxiosResponse> =>
    this.get(`${this.ADMIN_API_ROUTE}/company/resend-activation/${companyId}`);

  public toggleCompanyActivated = ({
    id,
    churn,
  }: {
    id: number;
    churn?: CompanyChurnData;
  }): Promise<AxiosResponse> => {
    return this.patch(`${this.ADMIN_API_ROUTE}/company/activate/${id}`, churn);
  };

  public createContract = (
    data: Contract
  ): Promise<AxiosResponse<ResponseOptions<Contract>>> => {
    return this.post(
      `${this.ADMIN_API_ROUTE}/company-contract/company/${data.company_id}`,
      data
    );
  };

  public editContract = (data: Contract): Promise<AxiosResponse<Contract>> => {
    return this.patch(
      `${this.ADMIN_API_ROUTE}/company-contract/contract/start-at/${data.id}`,
      data
    );
  };

  public bulkSaveContracts = (data: {
    contracts: Array<Partial<Contract>>;
    companyId: number;
  }): Promise<AxiosResponse<Array<Contract>>> => {
    return this.post(
      `${this.ADMIN_API_ROUTE}/company/contracts/${data.companyId}/sync`,
      {
        contracts: data.contracts,
      }
    );
  };

  public fetchPlatforms = (
    companyId: number
  ): Promise<AxiosResponse<Array<Platform>>> => {
    return this.get(`${this.ADMIN_API_ROUTE}/platforms/${companyId}`);
  };

  public createPlatform = (
    data: BlankPlatformOptions
  ): Promise<AxiosResponse<Platform>> => {
    return this.post(`${this.ADMIN_API_ROUTE}/platforms`, data);
  };

  public removePlatform = (
    platformId: number
  ): Promise<AxiosResponse<{ platformId: number }>> => {
    return this.delete(`${this.ADMIN_API_ROUTE}/platforms/${platformId}`);
  };

  public editPlatform = (data: Platform): Promise<AxiosResponse<Platform>> => {
    return this.put(`${this.ADMIN_API_ROUTE}/platforms/${data.id}`, data);
  };

  public fetchContractHistories = (
    params: ContractHistoriesParams
  ): Promise<AxiosResponse<ResponseOptions<Array<ContractHistory>>>> => {
    return this.get(
      `${this.ADMIN_API_ROUTE}/company-contract/histories/${params.companyId}`,
      {
        params: {
          limit: params.limit,
          offset: params.offset,
        },
      }
    );
  };

  public populateHistoricalLeads = (
    data: HistoricalLeadsData
  ): Promise<AxiosResponse> => {
    return this.put(
      `${this.ADMIN_API_ROUTE}/module/${data.companyId}/lead_stage/historical`,
      data
    );
  };

  public getAIModels = (): Promise<AxiosResponse> => {
    return this.get(`${this.ADMIN_API_ROUTE}/ai-settings/models`);
  };

  public getCompanyAIModels = (companyID: string) => {
    return this.get(`${this.ADMIN_API_ROUTE}/ai-settings/${companyID}`);
  };

  public postCompanyAIModel = (companyID: string, model: string) => {
    return this.post(`${this.ADMIN_API_ROUTE}/ai-settings/${companyID}`, {
      model_name: model,
    });
  };

  public deleteCompanyAIModel = (companyID: string, model: string) => {
    return this.delete(
      `${this.ADMIN_API_ROUTE}/ai-settings/${companyID}?model_name=${model}`
    );
  };
}
