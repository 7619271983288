import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';

import { DATE_FORMAT } from 'libs/constants';
import { Form } from 'libs/ui/components/Form';
import FormContent from './FormContent';

import useStyles from './styles';

interface Props {
  handleSearch: (data: { from: string; to: string }) => void;
}

const validationSchema = Yup.object({
  from: Yup.string()
    .label('From'),

  to: Yup.string()
    .label('To'),
});

const initialValues: { from: string; to: string } = {
  from: moment().startOf('month').format(DATE_FORMAT),
  to: moment().endOf('month').format(DATE_FORMAT),
};

const FilterByPeriodForm: React.FC<Props> = ({ handleSearch }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSearch}
        noValidate
      >
        <FormContent />
      </Form>
    </div>
  );
};

export default FilterByPeriodForm;
