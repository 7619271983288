import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

import useStyles from './styles';
import Dialog from '@material-ui/core/Dialog';

interface Props {
  isModalOpened: boolean;
  toggleModal: () => void;
  onApply?: () => void;
  onCancel?: () => void;
  title?: string;
  description?: string | (() => string | JSX.Element);
  cancelBtnText?: string;
  applyBtnText?: string;
  isErrorModal?: boolean;
}

const CustomModal: React.FC<Props> = ({
  isModalOpened,
  isErrorModal,
  toggleModal,
  title,
  description,
  onApply,
  onCancel,
  cancelBtnText,
  applyBtnText,
}) => {
  const classes = useStyles();

  const handleApply = (): void => {
    onApply ? onApply() : toggleModal();
  };

  const handleCancel = (): void => {
    onCancel ? onCancel() : toggleModal();
  };

  return (
    <Dialog open={isModalOpened} onClose={toggleModal}>
      <div className={classes.container}>
        { isErrorModal && <ErrorOutline className={classes.iconStyle} />}

        {
          title &&
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        }

        { typeof description === 'function' ? typeof description() === 'string' ? '' : description() : '' }

        <Typography paragraph className={classes.description}>
          { typeof description === 'function' ? typeof description() === 'string' ? description() : '' : description }
        </Typography>

        <div className={classes.buttonContainer}>
          {
            cancelBtnText && <Button
              type="button"
              color="default"
              variant="contained"
              onClick={handleCancel}
              className={classes.button}
            >
              <span className={classes.buttonText}>
                {cancelBtnText}
              </span>
            </Button>
          }

          {
            applyBtnText && <Button
              type="submit"
              color="secondary"
              variant="contained"
              onClick={handleApply}
              className={`${classes.buttonApply} ${classes.button}`}
            >
              <span className={classes.buttonText}>
                {applyBtnText}
              </span>
            </Button>
          }
        </div>
      </div>
    </Dialog>
  );
};

CustomModal.defaultProps = {
  title: 'Are you sure?',
  description: '',
  cancelBtnText: 'Cancel',
  applyBtnText: 'Yes',
  isErrorModal: true,
};

export default CustomModal;
