import { AxiosResponse, AxiosInstance } from 'axios';

import {
  ssoAppUrl,
  adminApiUrl,
  freeUsersApiUrl,
  casApiUrl,
} from 'libs/constants';
import { createHTTPClient } from './Http';

const httpClient: AxiosInstance = createHTTPClient();

export class BaseService {
  protected SSO_APP_HOST = ssoAppUrl;
  protected ADMIN_API_HOST = adminApiUrl;
  protected FREE_USERS_API_HOST = freeUsersApiUrl;
  protected CAS_API_HOST = casApiUrl;

  protected get(url: string, data?: object): Promise<AxiosResponse> {
    return httpClient.get(url, data);
  }

  protected post(url: string, data?: object): Promise<AxiosResponse> {
    return httpClient.post(url, data);
  }

  protected put(url: string, data?: object): Promise<AxiosResponse> {
    return httpClient.put(url, data);
  }

  protected patch(url: string, data?: object): Promise<AxiosResponse> {
    return httpClient.patch(url, data);
  }

  protected delete(url: string, data?: object): Promise<AxiosResponse> {
    return httpClient.delete(url, data);
  }
}
