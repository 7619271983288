import { AxiosResponse } from 'axios';

import { BaseService } from 'libs/services';
import { Recommender } from 'modules/recommenders/models';

export class RecommendersService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: RecommendersService;

  constructor() {
    super();

    if (RecommendersService._instance) {
      throw Error('Unable to create the instance!');
    }
  }

  static getInstance(): RecommendersService {
    if (!RecommendersService._instance) {
      RecommendersService._instance = new RecommendersService();
    }

    return RecommendersService._instance;
  }

  public fetchRecommenders = (page?: number): Promise<AxiosResponse> => this.get(`${this.ADMIN_API_ROUTE}/recommender`, { params: { page } });

  public fetchSingleRecommender = (recommenderId: number): Promise<AxiosResponse> => this.get(`${this.ADMIN_API_ROUTE}/recommender/${recommenderId}`);

  public createRecommender = (data: Recommender): Promise<AxiosResponse> => this.post(`${this.ADMIN_API_ROUTE}/recommender`, data);

  public editRecommender = (data: Recommender): Promise<AxiosResponse> => this.put(`${this.ADMIN_API_ROUTE}/recommender/${data.id}`, data);

  public sendContract = (recommenderId: number): Promise<AxiosResponse> => this.get(`${this.ADMIN_API_ROUTE}/recommender/${recommenderId}/resend`);

  public activateRecommender = ({ recommenderId, token }: { recommenderId: number; token: string }): Promise<AxiosResponse> => this.put(`${this.ADMIN_API_ROUTE}/recommender/${recommenderId}/activate/${token}`);
}
