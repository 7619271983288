import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getIsFetching, getMeOAuth } from 'modules/auth/store/selectors';
import { PendingView } from 'libs/components/common';

interface Props {
  children: React.ReactElement;
}

export const IsSuperAdminMiddleware: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const isFetching = useSelector(getIsFetching);
  const isLeadooAdmin = useSelector(getMeOAuth);
  const isNotAdmin = !isFetching && typeof isLeadooAdmin === 'boolean' && !isLeadooAdmin;

  useEffect(() => {
    if (isNotAdmin) {
      history.goBack();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotAdmin]);

  return (
    <PendingView isFetching={isFetching} message={ isNotAdmin ? 'Error 403: Unauthorized' : '' }>
      {children}
    </PendingView>
  );
};
