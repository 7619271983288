import { CreatedBot, AssignedBot } from 'modules/bot-created-count/models';
import { BotCreatedCountActionTypes } from './actions';
import * as actionTypes from './actionTypes';

export const fetchCreatedBots = (data?: { from?: string; to?: string }): actionTypes.FetchCreatedBots => ({
  type: BotCreatedCountActionTypes.FETCH_CREATED_BOTS,
  payload: {
    data,
  },
});

export const fetchCreatedBotsSuccess = (payload: Array<CreatedBot>): actionTypes.FetchCreatedBotsSuccess => ({
  type: BotCreatedCountActionTypes.FETCH_CREATED_BOTS_SUCCESS,
  payload,
});

export const fetchCreatedBotsError = (payload: Array<string>): actionTypes.FetchCreatedBotsError => ({
  type: BotCreatedCountActionTypes.FETCH_CREATED_BOTS_ERROR,
  payload,
});

export const fetchAssignedBots = (botId: number, from: string, to: string): actionTypes.FetchAssignedBots => ({
  type: BotCreatedCountActionTypes.FETCH_ASSIGNED_BOTS,
  payload: { botId, from, to },
});

export const fetchAssignedBotsSuccess = (payload: Array<AssignedBot>): actionTypes.FetchAssignedBotsSuccess => ({
  type: BotCreatedCountActionTypes.FETCH_ASSIGNED_BOTS_SUCCESS,
  payload,
});

export const fetchAssignedBotsError = (payload: Array<string>): actionTypes.FetchAssignedBotsError => ({
  type: BotCreatedCountActionTypes.FETCH_ASSIGNED_BOTS_ERROR,
  payload,
});

export const cleanAssignedBots = (): actionTypes.CleanAssignedBots => ({
  type: BotCreatedCountActionTypes.CLEAN_ASSIGNED_BOTS,
});
