import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import { BotCreatedCountService } from 'modules/bot-created-count/services';
import {
  fetchCreatedBotsSuccess,
  fetchCreatedBotsError,
  fetchAssignedBotsError,
  fetchAssignedBotsSuccess,
} from 'modules/bot-created-count/store/actions/actionCreators';
import * as actionTypes from 'modules/bot-created-count/store/actions/actionTypes';

const botCreatedCountService: BotCreatedCountService = BotCreatedCountService.getInstance();

export function* fetchCreatedBotsWorker({ payload }: actionTypes.FetchCreatedBots): SagaIterator {
  try {
    const { data } = yield call(botCreatedCountService.fetchCreatedBots, payload.data);
    yield put(fetchCreatedBotsSuccess(data));
  } catch (e) {
    yield put(fetchCreatedBotsError(e.response.data.message));
  }
}

export function* fetchAssignedBotsWorker({ payload }: actionTypes.FetchAssignedBots): SagaIterator {
  try {
    const { data } = yield call(botCreatedCountService.fetchAssignedBots, payload.botId, payload.from, payload.to);
    yield put(fetchAssignedBotsSuccess(data));
  } catch (e) {
    yield put(fetchAssignedBotsError(e.response.data.message));
  }
}
