import { SagaIterator } from 'redux-saga';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { AuthActionTypes } from 'modules/auth/store/actions/actions';
import * as authWorkers from './auth';

export function* authWatcher(): SagaIterator {
  yield all([
    takeEvery(AuthActionTypes.SIGN_IN, authWorkers.signInWorker),
    takeEvery(AuthActionTypes.SIGN_OUT, authWorkers.signOutWorker),
    takeLatest(AuthActionTypes.FETCH_ME_OAUTH, authWorkers.fetchMeOAuthWorker),
    takeLatest(AuthActionTypes.FETCH_ME_USER, authWorkers.fetchMeUserWorker),
    takeEvery(AuthActionTypes.SEND_EMAIL, authWorkers.sendEmailWorker),
    takeEvery(AuthActionTypes.RESTORE_PASSWORD, authWorkers.restorePasswordWorker),
    takeLatest(AuthActionTypes.FETCH_USER_BY_CODE, authWorkers.fetchUserByCodeWorker),
  ]);
}
