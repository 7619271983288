import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#263238',
    minWidth: 260,
  },
  collapsedContainer: {
    backgroundColor: '#263238',
    minWidth: 90,
  },
  title: {
    marginTop: 20,
    fontSize: 15,
    color: '#76838f',
    textTransform: 'uppercase',
    transition: 'all .25s,font .1s .15s,color .1s .15s',
  },
  regText: {
    color: '#76838f',
    marginLeft: 0,
    fontSize: 14,
  },
  sideBarText: {
    color: '#76838f',
    marginLeft: 14,
    fontSize: 14,
  },
  collapsedSidebarText: {
    color: '#76838f',
    marginLeft: 0,
    fontSize: 14,
  },
  collapsedListItemAccordion: {
    padding: 0,
    height: 0,
  },
  listItem: {
    paddingLeft: 25,
    height: 40,
  },
  listItemLink: {
    textDecoration: 'none',
    width: '100%',
  },
  collapsedListItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: 'rgba(163,175,183,.9)',
  },
  regionalLinks: {
    width: '100%',
  },
  accordion: {
    backgroundColor: 'inherit',

    '&:before': {
      backgroundColor: 'inherit',
    },
  },
});

export default useStyles;
