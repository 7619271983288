import {colors, Theme, ThemeOptions} from '@material-ui/core';

const white = '#FFFFFF';

const palette = {
  primary: {
    contrastText: '#f3f7fb',
    dark: '#6F73FC',
    main: '#4C50FC',
    light: '#4C50FCCC',
  },
  secondary: {
    contrastText: white,
    dark: '#42D78E',
    main: '#13CD72',
    light: colors.blue.A400,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
    leadoo: '#E84D40',
  },
  text: {
    primary: '#0b2b39',
    secondary: '#969696',
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    gray: '#F1F4F5',
    default: '#F4F6F8',
    paper: white,
  },
  divider: colors.grey[200],
};

const theme: ThemeOptions = {
  palette,
  typography: {
    fontFamily: 'Roboto,sans-serif',

    h1: {
      color: palette.text.primary,
      fontWeight: 700,
      fontSize: '35px',
      letterSpacing: '-0.24px',
      lineHeight: '40px',
    },
    h2: {
      color: palette.text.primary,
      fontWeight: 700,
      fontSize: '29px',
      letterSpacing: '-0.24px',
      lineHeight: '32px',
    },
    h3: {
      color: palette.text.primary,
      fontWeight: 700,
      fontSize: '24px',
      letterSpacing: '-0.06px',
      lineHeight: '28px',
    },
    h4: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.06px',
      lineHeight: '24px',
    },
    h5: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    },
    h6: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    },
    subtitle1: {
      color: palette.text.primary,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '25px',
    },
    subtitle2: {
      color: palette.text.secondary,
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body1: {
      color: palette.text.primary,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body2: {
      color: palette.text.secondary,
      fontSize: '12px',
      letterSpacing: '-0.04px',
      lineHeight: '18px',
    },
    button: {
      color: palette.text.primary,
      fontSize: '14px',
    },
    caption: {
      color: palette.text.secondary,
      fontSize: '11px',
      letterSpacing: '0.33px',
      lineHeight: '13px',
    },
    overline: {
      color: palette.text.secondary,
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '0.33px',
      lineHeight: '13px',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderColor: '#EEEEEE',
      },
      notchedOutline: {
        borderColor: '#0B2B39',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 700,
      },
      contained: {
        boxShadow: 'none',
        fontWeight: 700,
        '&$disabled': {
          color: white,
          backgroundColor: '#CBD7E0',
        },
        padding: '9px 16px 8px',
        borderRadius: '5px',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#C5CCD7',
      },
    },
  },
};

export type AppPalette = typeof palette;
export type AppThemeOptions = typeof theme & { palette: AppPalette };
export type AppTheme = Theme & { palette: AppPalette };

export default theme;
