import { AuthActionTypes } from './actions';

import { OAuth, User } from 'libs/models';
import * as actionTypes from './actionTypes';

export const signIn = (payload: { email: string; password: string }): actionTypes.SignIn => ({
  type: AuthActionTypes.SIGN_IN,
  payload,
});

export const signInSuccess = (): actionTypes.SignInSuccess => ({
  type: AuthActionTypes.SIGN_IN_SUCCESS,
});

export const signInError = (payload: Array<string>): actionTypes.SignInError => ({
  type: AuthActionTypes.SIGN_IN_ERROR,
  payload,
});

export const signOut = (): actionTypes.SignOut => ({
  type: AuthActionTypes.SIGN_OUT,
});

export const signOutSuccess = (): actionTypes.SignOutSuccess => ({
  type: AuthActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutError = (payload: Array<string>): actionTypes.SignOutError => ({
  type: AuthActionTypes.SIGN_OUT_ERROR,
  payload,
});

export const fetchMeUser = (): actionTypes.FetchMeUser => ({
  type: AuthActionTypes.FETCH_ME_USER,
});

export const fetchMeUserSuccess = (payload: User): actionTypes.FetchMeUserSuccess => ({
  type: AuthActionTypes.FETCH_ME_USER_SUCCESS,
  payload,
});

export const fetchMeUserError = (payload: Array<string>): actionTypes.FetchMeUserError => ({
  type: AuthActionTypes.FETCH_ME_USER_ERROR,
  payload,
});

export const fetchMeOAuth = (): actionTypes.FetchMeOAuth => ({
  type: AuthActionTypes.FETCH_ME_OAUTH,
});

export const fetchMeOAuthSuccess = (payload: OAuth): actionTypes.FetchMeOAuthSuccess => ({
  type: AuthActionTypes.FETCH_ME_OAUTH_SUCCESS,
  payload,
});

export const fetchMeOAuthError = (payload: Array<string>): actionTypes.FetchMeOAuthError => ({
  type: AuthActionTypes.FETCH_ME_OAUTH_ERROR,
  payload,
});

export const sendEmail = (): actionTypes.SendEmail => ({
  type: AuthActionTypes.SEND_EMAIL,
});

export const sendEmailSuccess = (payload: { message: string }): actionTypes.SendEmailSuccess => ({
  type: AuthActionTypes.SEND_EMAIL_SUCCESS,
  payload,
});

export const sendEmailError = (payload: Array<string>): actionTypes.SendEmailError => ({
  type: AuthActionTypes.SEND_EMAIL_ERROR,
  payload,
});

export const restorePassword = (payload: { newPassword: string }): actionTypes.RestorePassword => ({
  type: AuthActionTypes.RESTORE_PASSWORD,
  payload,
});

export const restorePasswordSuccess = (): actionTypes.RestorePasswordSuccess => ({
  type: AuthActionTypes.RESTORE_PASSWORD_SUCCESS,
});

export const restorePasswordError = (payload: Array<string>): actionTypes.RestorePasswordError => ({
  type: AuthActionTypes.RESTORE_PASSWORD_ERROR,
  payload,
});

export const fetchUserByCode = (payload: { code: string; pkceVerifier: string | null }): actionTypes.FetchUserByCode => ({
  type: AuthActionTypes.FETCH_USER_BY_CODE,
  payload,
});

export const fetchUserByCodeSuccess = (payload: { jwt: string; user: User }): actionTypes.FetchUserByCodeSuccess => ({
  type: AuthActionTypes.FETCH_USER_BY_CODE_SUCCESS,
  payload,
});

export const fetchUserByCodeError = (payload: Array<string>): actionTypes.FetchUserByCodeError => ({
  type: AuthActionTypes.FETCH_USER_BY_CODE_ERROR,
  payload,
});
