import React from 'react';
import { Form as FormikForm, Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';

interface Props<T> extends Omit<React.HTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  initialValues: T;
  validationSchema: Yup.ObjectSchema;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  noValidate?: boolean;
  enableReinitialize?: boolean;
  isNoForm?: boolean;
}

export const Form = <T extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit,
  validateOnChange,
  validateOnBlur,
  children,
  enableReinitialize = false,
  isNoForm = false,
  ...props
}: Props<T>): JSX.Element => (
    <Formik<T>
      initialValues={initialValues as T}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
    >
      {() => isNoForm ? <>{children}</> : <FormikForm {...props}>{children}</FormikForm>}
    </Formik>
  );
