import React from 'react';
import { Grid, Button, TextField, InputAdornment, Typography } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import moment from 'moment';

import { DATE_FORMAT } from 'libs/constants';
import { Field } from 'libs/ui/components/Form';

import useStyles from './styles';

const FormContent: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={3}>
        <Field
          fullWidth
          type="date"
          name="from"
          variant="outlined"
          component={TextField}
          size="small"
          inputProps={{
            min: moment('2018-01-01').format(DATE_FORMAT),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography>From</Typography>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <Field
          fullWidth
          type="date"
          name="to"
          variant="outlined"
          component={TextField}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography>To</Typography>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Button
        type="submit"
        name="search-btn"
        color="primary"
        variant="contained"
        className={classes.searchBtn}
      >
        <SearchIcon />
      </Button>
    </Grid>
  );
};

export default FormContent;
