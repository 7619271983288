import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    backgroundColor: '#ffffff',
    height: 66,
    width: '100%',
    flexWrap: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 260,
    width: '100%',
    backgroundColor: '#4B50FC',
    textAlign: 'center',
  },
  collapsedLogoBlock: {
    backgroundColor: '#4B50FC',
    maxWidth: 90,
    width: '100%',
    textAlign: 'center',
  },
  logo: {
    width: 170,
    height: 56,
  },
  collapsedLogo: {
    width: theme.breakpoints.down(1200) ? 56 : 170,
    height: 56,
  },
  controlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  expandButton: {
    color: '#76838f',
  },
  backButton: {
    textTransform: 'capitalize',
    marginLeft: '5%',
    borderRadius: '5px',
    background: '#FFFFFF',
    border: '2px solid #3D779E',
    color: '#3D779E',
    fontStyle: 'normal',
    fontFamily: 'OpenSans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px'
  },
  logout: {
    height: '100%',
    marginTop: 44,
  },
  logoutButtonText: {
    display: 'flex',
    alignItems: 'center',
  },
  userDropdown: {
    height: '100%',
  },
  userAvatar: {
    backgroundColor: 'rgb(12,138,188)',
    color: '#fff',
    borderRadius: 50,
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
