import { AxiosError } from 'axios';
import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import { SagaPayload } from 'libs/utils/types';

import { UserManagementService } from 'modules/user-management/services';
import {
  assignCompanyToUserError,
  assignCompanyToUserSuccess,
  deassignCompanyToUserSuccess,
  deassignCompanyToUserError,
  changeAdminError,
  changeAdminSuccess,
  fetchCompaniesToAssignError,
  fetchCompaniesToAssignSuccess,
  fetchUsersError,
  fetchUsersSuccess,
  offboardingUserSuccess,
  offboardingUserError,
} from 'modules/user-management/store/actions/actionCreators';
import {
  FetchUsers,
  ChangeAdmin,
  FetchCompaniesToAssign,
  AssignCompanyToUser,
  DeassignCompanyToUser,
  OffboardingUser,
} from 'modules/user-management/store/actions/actionTypes';
import { User } from 'libs/models';

const userManagementService: UserManagementService = UserManagementService.getInstance();

export function* fetchUsersWorker({ payload }: FetchUsers): SagaIterator {
  try {
    const { data } = yield call(userManagementService.fetchUsers, payload!.params);
    yield put(fetchUsersSuccess(data));
  } catch (e) {
    yield put(fetchUsersError((e as AxiosError).response?.data.message));
  }
}

export function* changeAdminWorker({ payload }: ChangeAdmin): SagaIterator {
  try {
    const {
      data
    }: SagaPayload<UserManagement.ChangeAdminSuccessPayload>
  = yield call(userManagementService.changeAdmin, payload);
    yield put(changeAdminSuccess(data));
  } catch (e) {
    yield put(changeAdminError((e as AxiosError).response?.data.message));
  }
}

export function* fetchCompaniesToAssignWorker({ payload }: FetchCompaniesToAssign): SagaIterator {
  try {
    const {
      data
    }: SagaPayload<User>
  = yield call(userManagementService.fetchCompaniesToAssign, payload);
    yield put(fetchCompaniesToAssignSuccess(data));
  } catch (e) {
    yield put(fetchCompaniesToAssignError((e as AxiosError).response?.data.message));
  }
}

export function* assignCompanyToUserWorker({ payload }: AssignCompanyToUser): SagaIterator {
  try {
    const {
      data
    }: SagaPayload<UserManagement.AssignCompanySuccessPayload>
  = yield call(userManagementService.assignCompanyToUser, payload.data);
    yield put(assignCompanyToUserSuccess(data.user));
  } catch (e) {
    yield put(assignCompanyToUserError((e as AxiosError).response?.data.message));
  }
}

export function* deassignCompanyToUserWorker({ payload }: DeassignCompanyToUser): SagaIterator {
  try {
    const {
      data: deassignData
    }: SagaPayload<string>
    = yield call(userManagementService.deassignCompanyToUser, payload);
    const {
      data: fetchData
    }: SagaPayload<User>
    = yield call(userManagementService.fetchCompaniesToAssign, payload.userId);
    yield put(fetchCompaniesToAssignSuccess(fetchData));
    yield put(deassignCompanyToUserSuccess(deassignData));
  } catch (e) {
    yield put(deassignCompanyToUserError([String((e as AxiosError).response?.statusText)]));
  }
}

export function* offboardingUserWorker({ payload }: OffboardingUser): SagaIterator {
  try {
    const { data } = yield call(userManagementService.offboardingUser, payload.email);
    yield put(offboardingUserSuccess(data));
  } catch (e) {
    yield put(offboardingUserError((e as AxiosError).response?.data.message));
  }
}
