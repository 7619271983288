import { isEmpty } from 'lodash';

import { UserManagementState } from 'modules/user-management/models';
import { UserManagementActionTypes } from 'modules/user-management/store/actions/actions';
import { UserManagementActions } from 'modules/user-management/store/actions/actionTypes';

const initialState: UserManagementState = {
  isFetching: false,
  successMessage: '',
  errorMessages: [],
  users: [],
  responsibleUsers: [],
  user: {},
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 15,
  totalItems: 0,
  totalPages: 1,
  noDataMessage: '',
};

const userManagementReducer = (state: UserManagementState = initialState, action: UserManagementActions): UserManagementState => {
  switch (action.type) {
  case UserManagementActionTypes.FETCH_USERS:
  case UserManagementActionTypes.CHANGE_ADMIN:
  case UserManagementActionTypes.FETCH_COMPANIES_TO_ASSIGN:
  case UserManagementActionTypes.ASSIGN_COMPANY_TO_USER:
  case UserManagementActionTypes.DEASSIGN_COMPANY_TO_USER:
  case UserManagementActionTypes.OFFBOARDING_USER: {
    return {
      ...state,
      isFetching: true,
      errorMessages: [],
      noDataMessage: '',
      successMessage: '',
    };
  }

  case UserManagementActionTypes.FETCH_USERS_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      noDataMessage: isEmpty(action.payload?.items) ? 'There are no user yet!' : '',
      users: action.payload?.items,
      currentPage: action.payload?.meta.currentPage,
      itemsPerPage: action.payload?.meta.itemsPerPage,
      itemCount: action.payload?.meta.itemCount,
      totalItems: action.payload?.meta.totalItems,
      totalPages: action.payload?.meta.totalPages,
    };
  }

  case UserManagementActionTypes.CHANGE_ADMIN_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      users: state.users.map(user => user.id === Number(action.payload.user_id) ? ({ ...user, is_admin: !user.is_admin }) : user),
    };
  }

  case UserManagementActionTypes.FETCH_COMPANIES_TO_ASSIGN_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      noDataMessage: isEmpty(action.payload) ? 'There are no companies to assign yet!' : '',
      user: action.payload,
    };
  }

  case UserManagementActionTypes.ASSIGN_COMPANY_TO_USER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      user: action.payload,
      successMessage: 'Successfully saved!',
    };
  }

  case UserManagementActionTypes.DEASSIGN_COMPANY_TO_USER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      successMessage: action.payload,
    };
  }

  case UserManagementActionTypes.OFFBOARDING_USER_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      errorMessages: [],
      users: state.users.filter(user => user.email !== action.payload.email),
    };
  }

  case UserManagementActionTypes.FETCH_USERS_ERROR:
  case UserManagementActionTypes.CHANGE_ADMIN_ERROR:
  case UserManagementActionTypes.FETCH_COMPANIES_TO_ASSIGN_ERROR:
  case UserManagementActionTypes.ASSIGN_COMPANY_TO_USER_ERROR:
  case UserManagementActionTypes.OFFBOARDING_USER_ERROR: {
    const errors = typeof action.payload === 'object' ? action.payload : [action.payload];

    return {
      ...state,
      isFetching: false,
      errorMessages: [
        ...state.errorMessages,
        ...errors,
      ],
    };
  }
  
  default:
    return state;
  }
};

export default userManagementReducer;
