import {SagaIterator} from 'redux-saga';
import {all, takeLatest} from 'redux-saga/effects';
import {fetchIntegrationsErrorsWorker, updateIntegrationsErrorWorker} from './sagas';
import {IntegrationsErrorsActionsTypes} from '../actions/actions';

export default function* (): SagaIterator {
  yield all([
    takeLatest(IntegrationsErrorsActionsTypes.FETCH_ERRORS_LIST, fetchIntegrationsErrorsWorker),
    takeLatest(IntegrationsErrorsActionsTypes.UPDATE_INTEGRATION_ERROR, updateIntegrationsErrorWorker),
  ]
  );
}
