import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { RecommendersService } from 'modules/recommenders/services';
import {
  createRecommenderError,
  createRecommenderSuccess,
  editRecommenderError,
  editRecommenderSuccess,
  fetchRecommendersError,
  fetchRecommendersSuccess,
  fetchSingleRecommenderError,
  fetchSingleRecommenderSuccess,
  sendRecommendersContractError,
  sendRecommendersContractSuccess,
  sendRecommendersContract,
  activateRecommenderError,
  activateRecommenderSuccess,
} from 'modules/recommenders/store/actions/actionCreators';
import {
  FetchRecommenders,
  FetchSingleRecommender,
  CreateRecommender,
  EditRecommender,
  SendRecommendersContract,
  ActivateRecommender,
} from 'modules/recommenders/store/actions/actionTypes';

const recommendersService = RecommendersService.getInstance();

export function* fetchRecommendersWorker({ payload }: FetchRecommenders): SagaIterator {
  try {
    const { data } = yield call(recommendersService.fetchRecommenders, payload!.data.page);
    yield put(fetchRecommendersSuccess(data));
  } catch (e) {
    yield put(fetchRecommendersError(e.response.data.message));
  }
}

export function* fetchSingleRecommenderWorker({ payload }: FetchSingleRecommender): SagaIterator {
  try {
    const { data } = yield call(recommendersService.fetchSingleRecommender, payload!.data.recommenderId);
    yield put(fetchSingleRecommenderSuccess(data));
  } catch (e) {
    if (e.response.status === 404) {
      yield put(push('/recommender'));
    }

    yield put(fetchSingleRecommenderError(e.response.data.message));
  }
}

export function* createRecommenderWorker({ payload }: CreateRecommender): SagaIterator {
  try {
    const { data } = yield call(recommendersService.createRecommender, payload!.data);

    yield put(createRecommenderSuccess(data));
    yield put(sendRecommendersContract(data.id));
    yield put(push('/recommender'));
  } catch (e) {
    yield put(createRecommenderError(e.response.data.message));
  }
}

export function* editRecommenderWorker({ payload }: EditRecommender): SagaIterator {
  try {
    const { data } = yield call(recommendersService.editRecommender, payload!.data);

    yield put(editRecommenderSuccess(data));
    yield put(push('/recommender'));
  } catch (e) {
    yield put(editRecommenderError(e.response.data.message));
  }
}

export function* sendRecommendersContractWorker({ payload }: SendRecommendersContract): SagaIterator {
  try {
    const { data } = yield call(recommendersService.sendContract, payload!.data.recommenderId);
    yield put(sendRecommendersContractSuccess(data));
  } catch (e) {
    yield put(sendRecommendersContractError(e.response.data.message));
  }
}

export function* activateRecommenderWorker({ payload }: ActivateRecommender): SagaIterator {
  try {
    const { data } = yield call(recommendersService.activateRecommender, {
      recommenderId: payload!.recommenderId,
      token: payload!.token,
    });

    yield put(activateRecommenderSuccess(data.message));
  } catch (e) {
    if (e.response.status === 400) {
      yield put(push('/recommender'));
    }

    yield put(activateRecommenderError(e.response.data.errors));
  }
}
