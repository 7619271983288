import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import useStyles from './styles';

const AddNewRow: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid container justifyContent="flex-end">
      <Link to={`${location.pathname}/create`} className={classes.addLink}>
        <AddIcon />
        <span>Add New</span>
      </Link>
    </Grid>
  );
};

export default AddNewRow;
