export enum MediaPartnerBotActionTypes {
  FETCH_BOTS = '[MEDIA_PARTNER_BOT_MODULE] FETCH_BOTS',
  FETCH_BOTS_SUCCESS = '[MEDIA_PARTNER_BOT_MODULE] FETCH_BOTS_SUCCESS',
  FETCH_BOTS_ERROR = '[MEDIA_PARTNER_BOT_MODULE] FETCH_BOTS_ERROR',

  FETCH_SINGLE_BOT = '[MEDIA_PARTNER_BOT_MODULE] FETCH_SINGLE_BOT',
  FETCH_SINGLE_BOT_SUCCESS = '[MEDIA_PARTNER_BOT_MODULE] FETCH_SINGLE_BOT_SUCCESS',
  FETCH_SINGLE_BOT_ERROR = '[MEDIA_PARTNER_BOT_MODULE] FETCH_SINGLE_BOT_ERROR',

  SHARE_BOT = '[MEDIA_PARTNER_BOT_MODULE] SHARE_BOT',
  SHARE_BOT_SUCCESS = '[MEDIA_PARTNER_BOT_MODULE] SHARE_BOT_SUCCESS',
  SHARE_BOT_ERROR = '[MEDIA_PARTNER_BOT_MODULE] SHARE_BOT_ERROR',
}
