import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    marginBottom: 10,
  },

  searchBtn: {
    padding: '6px 15px',
    marginLeft: 50,
  },

  inputWrapper: {
    display: 'flex',
  },

  input: {
    border: '1px solid #e4eaec',
  },

  inputGroup: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default useStyles;
