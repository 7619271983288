import { AxiosResponse } from 'axios';

import { BaseService } from 'libs/services';

export class MediaPartnerBotService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: MediaPartnerBotService;

  constructor() {
    super();

    if (MediaPartnerBotService._instance) {
      throw Error('Unable to create new instance!');
    }
  }

  static getInstance() {
    if (!MediaPartnerBotService._instance) {
      MediaPartnerBotService._instance = new MediaPartnerBotService();
    }

    return MediaPartnerBotService._instance;
  }

  public fetchBots = async (page?: number): Promise<AxiosResponse> => {
    const res = await this.get(`${this.ADMIN_API_ROUTE}/share-bot`, { params: { page } });
    return res;
  };

  public fetchSingleBot = async (botId: number): Promise<AxiosResponse> => {
    const res = await this.get(`${this.ADMIN_API_ROUTE}/share-bot/${botId}`);
    return res;
  };

  public shareBot = async (data: { botId: number; companies: Array<number> }): Promise<AxiosResponse> => {
    const res = await this.post(`${this.ADMIN_API_ROUTE}/share-bot/${data.botId}`, { companies: data.companies });
    return res;
  };
}
