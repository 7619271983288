import { Option } from 'libs/utils/types';

export const contractLengthOptions: Array<Option> = [
  {
    value: 1,
    title: '1',
  }, {
    value: 2,
    title: '2',
  }, {
    value: 3,
    title: '3',
  }, {
    value: 6,
    title: '6',
  }, {
    value: 12,
    title: '12',
  }, {
    value: 24,
    title: '24',
  }, {
    value: 36,
    title: '36',
  },
];

export const isMonthlyReportOptions: Array<Option> = [
  {
    title: 'Yes',
    value: 1
  },
  {
    title: 'No',
    value: 0
  },
];

export const industryTypeOptions: Array<Option> = [
  {
    title: 'B2B',
    value: 'b2b',
  },
  {
    title: 'B2C',
    value: 'b2c',
  },
  {
    title: 'B2B/B2C',
    value: 'b2b/b2c',
  },
  {
    title: 'Non Profit',
    value: 'non-profit',
  },
  {
    title: 'Unknown',
    value: 'Unknown',
  }
];

export const HISTORIES_PER_PAGE = 15;

export const NO_TITLE = '-';
